<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  disabled
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Операции по документу"
              label-for="doc_operations"
            >
              <template>Переоценка товара</template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Организация"
              label-for="entity"
            >
              <template v-if="fixed">
                {{ filter_params.entity.name }}
              </template>
              <e-select
                v-else
                v-model="active_entity"
                selected_type="text"
                :options="entity_list"
                select_name="entity_select"
              >
                <template #custom_name="{ item }">
                  <div class="entity">
                    <p class="name">{{ item.name }}</p>
                    <div class="inn_kpp">
                      <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                      <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                    </div>
                  </div>
                </template>
              </e-select>
            </b-form-group>
          </b-col>
          <!--          <b-col xl="2" lg="4" md="4" sm="6" class="pr-0">-->
          <!--            <b-form-group-->
          <!--              label="Место хранения"-->
          <!--              label-for="storage"-->
          <!--              :class="{ error: is_storage_error }"-->
          <!--            >-->
          <!--              <template v-if="fixed">-->
          <!--                {{ filter_params?.storage?.name }}-->
          <!--              </template>-->
          <!--              <template v-else>-->
          <!--                <i-select-->
          <!--                  id="storage"-->
          <!--                  multiple-->
          <!--                  :value="filter_params.storages"-->
          <!--                  v-model="rev_head.storages"-->
          <!--                  filterable-->
          <!--                  @on-change="changeStorage"-->
          <!--                  transfer-->
          <!--                >-->
          <!--                  <i-option-->
          <!--                    v-for="item in storage_list"-->
          <!--                    :value="item.id"-->
          <!--                    :key="item.id"-->
          <!--                  >-->
          <!--                    {{ item.name }}-->
          <!--                  </i-option>-->
          <!--                </i-select>-->
          <!--              </template>-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="updateHead"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { RevaluationHeadModel } from '@/views/service/revaluation/models/revaluation-head.model'
  import { mapGetters } from 'vuex'

  export default {
    name: 'RevaluationFilters',
    props: {
      filter_params: {
        type: RevaluationHeadModel,
        default: () => new RevaluationHeadModel()
      },
      fixed: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        entity_list: [],
        active_entity: [],
        rev_head: {
          date: new Date(),
          storages: [],
          comment: ''
        },
        date: this.filter_params.date
      }
    },
    computed: {
      ...mapGetters({ currentBranch: 'settings/getCurrentBranch' })
    },
    watch: {
      filter_params() {
        if (this.filter_params?.entity?.id) {
          this.active_entity = [this.filter_params.entity]
        }
      },
      active_entity(newVal) {
        this.filter_params.setEntity(newVal[0])
        this.$emit('change')
      }
    },

    mounted() {
      this.entity_list = this.currentBranch?.entities
      if (this.filter_params?.entity?.id) {
        this.active_entity = [this.filter_params?.entity]
      }
    },
    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      updateHead() {
        this.$emit('change', this.rev_head)
      },
      changeStorage(val) {
        this.rev_head.storage = val
        this.$emit('change', this.rev_head)
      },
      setDate() {
        this.$emit('change', this.date)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ms__dropdown-item.active {
    .name {
      color: #00a3ff;
    }
  }
  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  .params-body {
    padding-top: 16px;
  }
</style>
