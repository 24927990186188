<template>
  <b-modal
    id="modal-price-up"
    centered
  >
    <template #modal-header>
      <h5>{{ operation !== 'commission' ? 'Наценка товара' : 'Назначение % комиссии' }}</h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <b-form @submit.stop.prevent="submitPriceUp">
      <div class="d-flex">
        <div class="item-row">
          <label for="price-up"> {{ operation !== 'commission' ? 'Наценка' : 'Комиссия' }}</label>
          <b-form-input
            id="price-up"
            v-model="round.value"
            type="number"
          />
        </div>
        <div
          v-if="operation !== 'commission'"
          class="item-row ml-3"
        >
          <label for="round">Округлить</label>
          <i-select
            id="round"
            v-model="round.priceup"
          >
            <i-option
              v-for="item in roundList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
        </div>
      </div>
    </b-form>
    <template #modal-footer>
      <div class="d-flex">
        <b-button
          variant="primary"
          class="mr-2"
          @click="submitPriceUp"
        >
          Наценить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    props: {
      operation: String
    },
    data: () => ({
      round: {
        value: 0,
        priceup: 'rubles'
      },
      roundList: [
        {
          name: 'До рубля',
          id: 'rubles'
        },
        {
          name: 'До 50 коп.',
          id: 'half'
        },
        {
          name: 'Не округлять',
          id: 'none'
        }
      ]
    }),

    methods: {
      submitPriceUp() {
        this.$emit('price_up', this.round)
      },

      hideModal() {
        this.round.value = 0
        this.$bvModal.hide('modal-price-up')
      }
    }
  }
</script>
