<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <e-button
          size="m"
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            class="ml-3"
            :class="{ rotate: visibleParams }"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </e-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!document?.fixed"
          variant="outline-primary"
          class="cursor"
          size="m"
          @click="back"
        >
          Сохранить как черновик
        </e-button>
        <e-button
          class="ml-2"
          :loading="loading"
          :disabled="loading"
          variant="primary"
          size="m"
          @click="fixed"
        >
          <template v-if="!document?.fixed">Провести</template>
          <template v-if="document?.fixed">Редактировать</template>
        </e-button>
      </div>
    </div>
    <e-collapse
      id="collapse-filters"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <revaluation-filters
          ref="header_filters_incoming"
          :fixed="document?.fixed"
          :filter_params="document"
          @change="change"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import RevaluationFilters from '@/views/service/revaluation/components/RevaluationFilters'
  import { RevaluationHeadModel } from '@/views/service/revaluation/models/revaluation-head.model'
  export default {
    name: 'RevaluationHead',
    components: { RevaluationFilters },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      document: {
        type: RevaluationHeadModel,
        default: () => new RevaluationHeadModel()
      }
    },
    data() {
      return {
        visibleParams: false
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      fixed() {
        this.$emit('fixed', !this.document.fixed)
      },
      change(val) {
        if (typeof val?.comment === 'string') this.document.setComment(val.comment)
        this.$emit('update-head', this.document)
      },
      back() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      .form {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 0;
        max-width: 1440px;

        .col-lg-4 {
          margin-bottom: 16px !important;
        }
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
      }

      @media screen and (max-width: 1290px) and (min-width: 1200px) {
        .col-xl-2 {
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
        }
      }
    }

    .elm-calendar input {
      height: 31px !important;
    }
  }

  .params-body {
    .form {
      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-4 {
        margin-bottom: 16px !important;
      }

      // @media (max-width: 1200px) {}
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }
  }
  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
