var render = function render(){
  var _vm$selected, _vm$selected$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "revaluation-document"
    }
  }, [_c('product-movement-modal', {
    attrs: {
      "product": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : (_vm$selected$ = _vm$selected[0]) === null || _vm$selected$ === void 0 ? void 0 : _vm$selected$.product
    }
  }), _c('modal-price-up', {
    on: {
      "price_up": _vm.totalUpdateMarkup
    }
  }), _c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.includedCategories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('revaluation-head', {
    staticClass: "mb-3",
    attrs: {
      "document": _vm.head,
      "loading": _vm.loading
    },
    on: {
      "fixed": _vm.fixDocument,
      "update-head": _vm.updateHead
    }
  }), _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('div', {
    staticClass: "b-table__content"
  }, [_c('div', {
    staticClass: "flex-card"
  }, [_c('documents-table-filter', {
    attrs: {
      "placeholder": "Поиск",
      "load_categories": true,
      "edit_fields": true
    }
  }), _c('resizable-table', {
    staticStyle: {
      "margin": "0 !important"
    },
    attrs: {
      "inside_card": false,
      "height": "100%",
      "table_name": "revaluation_spec",
      "default_fields": _vm.fields,
      "items": _vm.head.specifications.specification,
      "show_product_search": true,
      "busy": _vm.firstLoading
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown
    },
    scopedSlots: _vm._u([{
      key: "productSearch",
      fn: function () {
        return [!_vm.head.fixed ? _c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "200px"
          }
        }, [_c('product-search', {
          attrs: {
            "document_type": "revaluation",
            "document_head": _vm.head.id,
            "document": _vm.head
          },
          on: {
            "select_product": _vm.select_product
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "total",
      fn: function () {
        return [_c('div', {
          staticClass: "ml-auto d-flex"
        }, [_c('div', {
          staticClass: "footer-data"
        }, [_vm._v("Итого: " + _vm._s(_vm.head.specifications.total))])])];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          on: {
            "change": _vm.setSelected
          },
          model: {
            value: _vm.isAllSelected,
            callback: function ($$v) {
              _vm.isAllSelected = $$v;
            },
            expression: "isAllSelected"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_number",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.order) + " ")];
      }
    }, {
      key: "body_id",
      fn: function (_ref2) {
        var _vm$selected2;

        var item = _ref2.item;
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected2 = _vm.selected) === null || _vm$selected2 === void 0 ? void 0 : _vm$selected2.some(function (el) {
              return el.id === (item === null || item === void 0 ? void 0 : item.id);
            })
          },
          on: {
            "change": function (val) {
              return _vm.addProduct(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "text-left",
          staticStyle: {
            "width": "85%"
          }
        }, [_c('product-spec-info', {
          attrs: {
            "product": data.value
          }
        })], 1)])];
      }
    }, {
      key: "body_delta",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('table-row-input', {
          ref: `delta_${item.id}`,
          attrs: {
            "value": item.delta,
            "input_id": `delta_${item.id}`,
            "fixed": _vm.head.fixed
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, item, true);
            },
            "input": function (val) {
              return _vm.setDelta(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref4) {
        var _item$product$measure, _item$product, _item$product$measure2;

        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_item$product$measure = (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$measure2 = _item$product.measurement) === null || _item$product$measure2 === void 0 ? void 0 : _item$product$measure2.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : '-') + " ")];
      }
    }, {
      key: "body_retailPrice",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_delta_price",
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_delta_percent",
      fn: function (_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(isFinite(value) ? value + ' %' : '-') + " ")];
      }
    }, {
      key: "body_delta_sum",
      fn: function (_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_purchasePrice",
      fn: function (_ref9) {
        var value = _ref9.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_new_sum",
      fn: function (_ref10) {
        var value = _ref10.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_retail_sum",
      fn: function (_ref11) {
        var value = _ref11.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_purchase_sum",
      fn: function (_ref12) {
        var value = _ref12.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_new_retailPrice",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('table-row-input', {
          ref: `price${item.id}`,
          attrs: {
            "value": item.new_retailPrice,
            "input_id": `price${item.id}`,
            "fixed": _vm.head.fixed,
            "symbol": "₽"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, item);
            },
            "input": function (val) {
              return _vm.setPrice(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_markup",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('table-row-input', {
          ref: `markup_${item.id}`,
          attrs: {
            "value": item.markup,
            "input_id": `markup_${item.id}`,
            "fixed": _vm.head.fixed,
            "symbol": "%"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setMarkup(val, item);
            },
            "input": function (val) {
              return _vm.setMarkup(val, item);
            }
          }
        })];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": 'document_product'
    },
    on: {
      "remove_items": _vm.removeItems,
      "show_move": _vm.showMove,
      "print_price_tags": _vm.printPrice
    }
  }), _c('modal-print-price-tags', {
    attrs: {
      "modal_id": "global_modal",
      "products": _vm.printProducts
    }
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }