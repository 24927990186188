<template>
  <section id="revaluation-document">
    <product-movement-modal :product="selected?.[0]?.product" />
    <modal-price-up @price_up="totalUpdateMarkup" />
    <categories-load-modal
      :included_categories="includedCategories"
      @add-categories="addCategories"
    />
    <!-- <breadcrumbs :extra="head" /> -->
    <revaluation-head
      class="mb-3"
      :document="head"
      :loading="loading"
      @fixed="fixDocument"
      @update-head="updateHead"
    />
    <div class="b-table__wrapper">
      <div class="b-table__content">
        <div class="flex-card">
          <documents-table-filter
            placeholder="Поиск"
            :load_categories="true"
            :edit_fields="true"
          />
          <resizable-table
            :inside_card="false"
            height="100%"
            style="margin: 0 !important"
            table_name="revaluation_spec"
            :default_fields="fields"
            :items="head.specifications.specification"
            :show_product_search="true"
            :busy="firstLoading"
            @scroll-up="scrollUp"
            @scroll-down="scrollDown"
          >
            <template #productSearch>
              <div
                v-if="!head.fixed"
                class="d-flex"
                style="width: 200px"
              >
                <product-search
                  document_type="revaluation"
                  :document_head="head.id"
                  :document="head"
                  @select_product="select_product"
                />
              </div>
            </template>

            <template #total>
              <div class="ml-auto d-flex">
                <div class="footer-data">Итого: {{ head.specifications.total }}</div>
              </div>
            </template>

            <template #head_id>
              <b-checkbox
                v-model="isAllSelected"
                @change="setSelected"
              />
            </template>

            <template #body_number="{ item }">
              {{ item.order }}
            </template>

            <template #body_id="{ item }">
              <div @click.stop>
                <b-form-checkbox
                  :checked="selected?.some((el) => el.id === item?.id)"
                  @change="(val) => addProduct(val, item)"
                />
              </div>
            </template>

            <template #body_product="data">
              <div class="d-flex justify-content-between align-items-center">
                <div
                  class="text-left"
                  style="width: 85%"
                >
                  <product-spec-info :product="data.value" />
                </div>
              </div>
            </template>

            <template #body_delta="{ item }">
              <table-row-input
                :ref="`delta_${item.id}`"
                :value="item.delta"
                :input_id="`delta_${item.id}`"
                :fixed="head.fixed"
                @handle-input="(val) => setDelta(val, item, true)"
                @input="(val) => setDelta(val, item)"
              />
            </template>

            <template #body_measurement="{ item }">
              {{ item.product?.measurement?.name ?? '-' }}
            </template>

            <template #body_retailPrice="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_delta_price="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_delta_percent="{ value }">
              {{ isFinite(value) ? value + ' %' : '-' }}
            </template>

            <template #body_delta_sum="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_purchasePrice="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_new_sum="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_retail_sum="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_purchase_sum="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_new_retailPrice="{ item }">
              <table-row-input
                :ref="`price${item.id}`"
                :value="item.new_retailPrice"
                :input_id="`price${item.id}`"
                :fixed="head.fixed"
                symbol="₽"
                @handle-input="(val) => setPrice(val, item)"
                @input="(val) => setPrice(val, item)"
              />
            </template>

            <template #body_markup="{ item }">
              <table-row-input
                :ref="`markup_${item.id}`"
                :value="item.markup"
                :input_id="`markup_${item.id}`"
                :fixed="head.fixed"
                symbol="%"
                @handle-input="(val) => setMarkup(val, item)"
                @input="(val) => setMarkup(val, item)"
              />
            </template>
          </resizable-table>
          <table-navbar
            :items="selected"
            :items_name="'document_product'"
            @remove_items="removeItems"
            @show_move="showMove"
            @print_price_tags="printPrice"
          />
          <modal-print-price-tags
            modal_id="global_modal"
            :products="printProducts"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import RevaluationHead from '@/views/service/revaluation/components/RevaluationHead'
  import ResizableTable from '@/components/ResizableTable'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import { RevaluationHeadModel } from '@/views/service/revaluation/models/revaluation-head.model'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import { ProductModel } from '@/models/product.model'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags.vue'
  import { mapActions } from 'vuex'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'

  export default {
    name: 'Revaluation',
    components: {
      ModalPrintPriceTags,
      ProductMovementModal,
      TableNavbar,
      ProductSpecInfo,
      TableRowInput,
      ProductSearch,
      DocumentsTableFilter,
      ResizableTable,
      RevaluationHead,
      CategoriesLoadModal,
      ModalPriceUp
    },
    apollo: {
      RevaluationHead: {
        query: require('../gql/getRevaluationHead.graphql'),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.params.id
            },
            pagination: this.pagination
          }
        },
        result({ data }) {
          if (!data) return
          this.getHead(data)
        }
      }
    },
    data() {
      return {
        loading: false,
        includedCategories: [],
        isAllSelected: false,
        printProducts: [],
        selected: [],
        debounce: null,
        firstLoading: true,
        fields: [
          { key: 'id', label: '', checked: true, width: 55 },
          { key: 'order', label: '№', checked: true, width: 45 },
          {
            key: 'product',
            label: 'Наименование товара',
            checked: true,
            width: 250
          },
          {
            key: 'delta',
            label: 'Общее количество',
            checked: true,
            width: 145
          },
          { key: 'measurement', label: 'Ед. изм.', checked: true, width: 80 },
          {
            key: 'retailPrice',
            label: 'Текущая цена, ₽',
            checked: true,
            width: 130
          },
          {
            key: 'new_retailPrice',
            label: 'Новая цена, ₽',
            checked: true,
            width: 120
          },
          {
            key: 'delta_price',
            label: 'Изменение цены, ₽',
            checked: true,
            width: 150
          },
          {
            key: 'delta_percent',
            label: 'Изменение цены, %',
            checked: true,
            width: 150
          },
          {
            key: 'delta_sum',
            label: 'Сумма изменения, ₽',
            checked: true,
            width: 160
          },
          {
            key: 'purchasePrice',
            label: 'Закупочная цена, ₽',
            checked: true,
            width: 80
          },
          {
            key: 'markup',
            label: 'Наценка, %',
            checked: true,
            width: 105
          },
          {
            key: 'new_sum',
            label: 'Сумма в новых ценах, ₽',
            checked: true,
            width: 210
          },
          {
            key: 'remainders',
            label: 'Остаток',
            checked: true,
            width: 90
          },
          {
            key: 'purchase_sum',
            label: 'Сумма в закупочных ценах, ₽',
            checked: true,
            width: 210
          },
          {
            key: 'retail_sum',
            label: 'Сумма в розничных ценах, ₽',
            checked: true,
            width: 210
          }
        ],
        head: new RevaluationHeadModel(),
        pagination: {
          skip: 0,
          take: 10
        }
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      getHead(data) {
        const oldspec = !this.firstLoading ? this.head.specifications.specification || [] : []
        this.head = new RevaluationHeadModel(data.RevaluationHead)
        this.setBreadcrumbs({ ...data.RevaluationHead, is_go_back: true })
        if (!this.firstLoading) {
          // const newDoc = new PaymentHeadModel(data.IncomingHead)
          if (!this.forwardDirection) {
            this.head.specifications.specification?.unshift(...oldspec)
          } else {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            this.head.specifications.specification?.push(...oldspec)
            if (oldspec.length > 0)
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
          }
        }

        this.firstLoading = false
      },
      async removeItems() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/removeRevaluationSpec.graphql'),
          variables: {
            input: {
              document_head: this.head.id,
              ids: this.selected.map((el) => el.id),
              isAllSelected: this.isAllSelected
            }
          }
        })

        if (data?.RemoveRevaluationSpec?.status) {
          this.head.specifications.specification = []
          this.firstLoading = true
          this.pagination.skip = 0
          this.$apollo.queries.RevaluationHead.refresh()
        }
      },
      async scrollDown() {
        const lastItem = this.head.specifications.specification?.[this.head.specifications.specification?.length - 1]
        if (!lastItem) return
        const pagination = this.pagination
        this.forwardDirection = false
        pagination.skip = lastItem.order
        pagination.take = this.pagination.take
        if (pagination.skip >= this.head.specifications.total) {
          return
        }
        this.pagination = pagination
        await this.$apollo.queries.RevaluationHead.refresh()
        this.pagination.take = 10
      },
      async scrollUp() {
        const firstElement = this.head.specifications.specification?.[0]
        if (!firstElement || firstElement?.order <= 1) return
        const pagination = Object.assign(this.pagination)
        this.forwardDirection = true
        pagination.skip = firstElement.order - pagination.take - 1
        if (pagination.skip < 0) {
          pagination.take = pagination.take + pagination.skip
          pagination.skip = 0
        }
        this.fetchHead(pagination)
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../gql/getRevaluationHead.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
            },
            pagination: pagination
          }
        })
        this.getHead(data)
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      async addCategories(cats) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createFromCategory.graphql'),
          variables: {
            input: {
              categories: cats,
              document_head: this.head.id
            }
          }
        })

        if (data) this.$apollo.queries.RevaluationHead.refresh()
      },
      addProduct(val, prod) {
        if (val) this.selected = [...this.selected, prod]
        else {
          this.selected = this.selected?.filter((el) => el.id !== prod.id)
        }
      },
      async getByOrder(spec, cb) {
        const pagination = this.pagination
        pagination.skip = spec.order - 2
        if (pagination.skip > this.head.specifications.total - pagination.take)
          pagination.skip = this.head.specifications.total - pagination.take
        this.firstLoading = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      scroll_to(item) {
        setTimeout(() => {
          this.$refs['price' + item.specification.id].showInput()
        }, 100)
        document.querySelector('.table-docs').scrollTo({
          top: this.$refs['price' + item.specification.id].$el.offsetTop - 80
        })
      },
      async select_product(product) {
        if (product.specification) {
          if (!this.$refs['price' + product.specification.id]) {
            this.getByOrder(product.specification, () => this.scroll_to(product))
          } else {
            this.scroll_to(product)
          }

          return
        }

        this.createSpec(product)
      },
      async createSpec(product) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createRevaluationSpec.graphql'),
          variables: {
            input: {
              product: product.id,
              document_head: this.head.id
            }
          }
        })

        await this.getLast(() => {
          setTimeout(() => {
            document.querySelector('.table-docs').scrollTo({
              top: document.querySelector('.table-docs').scrollHeight,
              left: 0
            })
            this.$refs['price' + data.CreateRevaluationSpec.id].showInput()
          }, 500)
          this.$nextTick(() => {
            this.$refs['price' + data.CreateRevaluationSpec.id].showInput()
          })
        })

        this.$noty.show('Добавлено')

        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },
      async getLast(cb) {
        this.forwardDirection = true
        const pagination = Object.assign(this.pagination)
        pagination.skip = this.head.specifications.total - pagination.take + 1
        if (pagination.skip < 0) pagination.skip = 0
        this.firstLoading = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      setDelta(val, item, isEnter) {
        item.setDelta(val)
        if (isEnter) document.getElementById(`price${item.id}`).click()
        this.updateSpec(item)
      },
      setPrice(val, item) {
        item.setNewPrice(val)
        this.updateSpec(item)
      },
      setMarkup(val, item) {
        item.setMarkup(val)
        this.updateSpec(item)
      },
      async updateSpec(spec) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/createRevaluationSpec.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: { ...spec.input, document_head: this.head.id }
            }
          })
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка обновления спецификации')
        }
      },
      async updateHead(head) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require('../gql/updateRevaluationHead.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                id: head.id,
                comment: head.comment,
                entity: head.entity
              }
            }
          })
        }, 500)
      },
      async fixDocument(fixed) {
        try {
          this.loading = true
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/fixRevaluationHead.graphql'),
            variables: {
              input: {
                fixed,
                id: this.head.id
              }
            }
          })

          if (data?.FixRevaluationHead?.status) {
            if (fixed) this.$router.back()
            this.pagination = {
              skip: 0,
              take: 10
            }
            this.firstLoading = true
            await this.$apollo.queries.RevaluationHead.refresh()
          }
        } catch (e) {
          this.loading = false
          console.error(e)
          this.$noty.error(e?.graphQLErrors?.[0]?.extensions?.ru ?? 'Ошибка')
        } finally {
          this.loading = false
        }
      },
      setSelected(val) {
        if (val) this.selected = this.head.specifications.specification
        else this.selected = []
      },
      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/addRevaluationMarkup.graphql'),
          variables: {
            input: {
              document_head: this.head.id,
              ids: this.selected.map((el) => el.id),
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddRevaluationMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.firstLoading = true
          this.$apollo.queries.RevaluationHead.refresh()
        }
      },
      showMove() {
        this.$bvModal.show('product-movement')
      },
      async printPrice(value) {
        this.printProducts = value?.map((el) => new ProductModel(el.product))
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      }
    },
    watch: {
      selected(val) {
        this.isAllSelected = val.length === this.head.specifications.specification.length
      },
      head() {
        setTimeout(() => {
          this.$nextTick(() => {
            const table = document.querySelector('.table-docs')
            if (table.scrollHeight === table.clientHeight) {
              this.scrollDown()
            }
          })
        }, 100)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #revaluation-document {
      overflow: hidden;
      height: 100%;
    }

    .p-0 {
      display: flex;
      flex-direction: column;
    }

    .flex-card {
      overflow: visible !important;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }

          th {
            padding: 16px !important;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    table-filter {
      button {
        background: #efefef;
        color: #313131;
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }

          th {
            padding: 16px !important;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        width: 100%;
        z-index: 10;
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;
        z-index: 10000;
        height: 400px;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .b-table__navbar {
      margin: -85px 15px 0;
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }
  }

  #revaluation-document {
    overflow: hidden;
    height: 100%;

    .flex-card {
      flex-grow: 1;
      min-height: 1px;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &__wrapper {
        & > div {
          position: fixed;
          z-index: 10;

          & > div {
            position: fixed;
          }
        }
      }

      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
</style>
